import React from 'react'
import DemoLayoutContent from './demo-only-components/DemoLayoutContent.es6.js'

function Welcome () {
  return (
    <DemoLayoutContent>
      <div className="ds-welcome">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M488 8H24C10.745 8 0 18.745 0 32v352a8 8 0 0 0 8 8h182.24l-12.8 64H160a8 8 0 0 0-8 8v24h-16v16h240v-16h-16v-24a8 8 0 0 0-8-8h-17.453l-12.8-64H504a8 8 0 0 0 8-8V32c0-13.255-10.745-24-24-24zM328 472h16v16H168v-16h160zm-134.24-16l12.788-64h98.892l12.8 64H193.76zM496 376H16v-72h480v72zM384 80h-64a8 8 0 0 0-8 8v72h16V96h48v32h-32v16h32v40h-32v16h32v40h-32v16h32v32h-48v-64h-16v64h-32v-40c0-32.648 39.648-39.824 41.312-40.112a8 8 0 0 0 5.544-12l-72-120a8.311 8.311 0 0 0-13.712 0l-72 120a8 8 0 0 0 5.52 12C176.352 208.176 216 215.352 216 248v40h-32v-64h-16v64h-8V144h8v16h16v-24a7.908 7.908 0 0 0-.648-3.152l-24-56a8 8 0 0 0-14.704 0l-24 56A7.908 7.908 0 0 0 120 136v152H56V64h400v224h-64V88a8 8 0 0 0-8-8zM232 264v-8h32v8h-32zm32 16v8h-32v-8h32zm-32.584-40c-3.832-25.44-25.616-39.2-43.04-45.08L240 108.885V176h16v-67.115l51.624 86.035c-17.44 5.84-39.208 19.64-43.04 45.08h-33.168zm-91.301-112L152 100.272 163.885 128h-23.77zM144 144v144h-8V144h8zm352 144h-24V56a8 8 0 0 0-8-8H48a8 8 0 0 0-8 8v232H16V32a8 8 0 0 1 8-8h464a8 8 0 0 1 8 8v256z"/><path d="M256 312c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm0 32a8 8 0 1 1 0-16 8 8 0 0 1 0 16z"/></svg>
      </div>
    </DemoLayoutContent>
  )
}

export default Welcome
